import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/core";
import moment from "moment";

import styles from "../styles";
import Layout from "../components/layout";
import { HeroSection, ContentSection } from "../components/section";

import SEO from "../components/seo";

const RenderBlogPost = ({ uid, title, publishedDate, content }) => {
  return (
    <div
      css={css`
        margin: 1rem 0 3rem;
        h2 > a {
          display: inline-block;
          cursor: pointer;
          color: ${styles.colors.black};
          text-decoration: none;
          font-size: ${styles.text.size2xl};
          font-weight: 500;
          &:hover {
            color: ${styles.colors.primary};
          }
        }

        p {
          font-weight: 300;
          line-height: 1.5;
        }

        time {
          display: block;
          font-size: ${styles.text.sizexs};
          color: ${styles.colors.black};
        }
      `}
    >
      <h2>
        <Link to={`/blog/${uid}`} activeClassName="current-page">
          {title}
        </Link>
      </h2>
      <p>{content}</p>
      {publishedDate && (
        <time datetime={moment(publishedDate).format("YYYY-MM-DDTHH:mm")}>
          {moment(publishedDate).format("MMMM DD, YYYY")}
        </time>
      )}
    </div>
  );
};

const RenderNoBlog = () => {
  return (
    <div
      css={css`
        font-weight: 200;
      `}
    >
      No blogs have published yet. Check back later!
    </div>
  );
};

const BlogPage = ({
  data: {
    prismicBlogpage: page,
    allPrismicBlogpost: { blogPosts },
  },
}) => {
  return (
    <Layout>
      <SEO
        title={page.data.metaTitle || undefined}
        description={page.data.metaDescription || undefined}
        robots={page.data.metaRobots || undefined}
      />

      <ContentSection>
        <h3
          css={css`
            font-size: ${styles.text.size3xl};
            font-weight: 300;
          `}
        >
          {page.data.heading}
        </h3>
      </ContentSection>

      {blogPosts && blogPosts.length > 1}
      <ContentSection>
        {blogPosts.map(post => (
          <RenderBlogPost
            uid={post.uid}
            title={post.data.title.text}
            publishedDate={post.data.releaseDate}
            content={post.data.metaDescription}
          />
        ))}
        {blogPosts.length == 0 && <RenderNoBlog></RenderNoBlog>}
      </ContentSection>
    </Layout>
  );
};

export const query = graphql`
  query {
    prismicBlogpage {
      data {
        heading
        metaDescription: meta_description
        metaRobots: meta_robots
        metaTitle: meta_title
      }
    }

    allPrismicBlogpost {
      blogPosts: nodes {
        uid
        data {
          releaseDate: release_date
          title {
            text
          }
          metaDescription: meta_description
          metaTitle: meta_title
        }
      }
    }
  }
`;

export default BlogPage;
